import { Controller } from "@hotwired/stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ 'other', 'feedback' ]
  static values = {
    feedback: String,
    path: String,
    userId: Number
  }

  connect () {
    if (this.feedbackValue == 'saved') {
      this.showSavedFeedback()
    }
  }

  showSavedFeedback () {
    let feedbackClassList = this.feedbackTarget.classList
    feedbackClassList.add('opacity-100')
    this.feedbackTarget.innerHTML = `Response saved! View your <a href="/users/saved_responses"><u>Saved Responses</u></a> in My Profile.`

    setTimeout(function () {
      feedbackClassList.remove('opacity-100')
    }, 5000)
  }

  blur (e) {
    if (e.relatedTarget && e.relatedTarget.dataset.surveyId != e.currentTarget.dataset.surveyId) {
      this.submit()
    } else if (e.relatedTarget == null) {
      this.submit()
    }
  }

  submit() {
    if (this.userIdValue) {
      let responses = []

      this.element.querySelectorAll('input:checked').forEach(function(it) {
        responses.push(it.value)
      })

      if (this.hasOtherTarget && this.otherTarget.value.length > 0) {
        this.updateResponse(responses, this.otherTarget.value)
      } else {
        this.updateResponse(responses, null)
      }
    } else {
      alert("Sign in or Create an Account to save your response.")
    }
  }

  updateResponse (survey_option_ids, text) {
    Rails.ajax({
      type: 'PUT',
      data: new URLSearchParams({
        survey_option_ids: survey_option_ids,
        text: text
      }).toString(),
      url: this.pathValue,
      success: (_data, _status, xhr) => {
        this.element.outerHTML = xhr.response
      }
    })
  }
}
