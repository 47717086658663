import { Controller } from "@hotwired/stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    path: String,
    theme: String,
    userId: Number
  }

  toggle({ params }) {
    if (this.userIdValue) {
      this.toggleBookmark(!params.bookmarked)
    } else {
      alert("Sign in or Create an Account to bookmark " + this.themeValue + '.')
    }
  }

  toggleBookmark(shouldBookmark) {
    Rails.ajax({
      type: 'PUT',
      data: new URLSearchParams({ bookmark: shouldBookmark, theme: this.themeValue }).toString(),
      url: this.pathValue,
      success: (_data, _status, xhr) => {
        this.element.outerHTML = xhr.response
      }
    })
  }
}
