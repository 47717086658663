import { Controller } from "@hotwired/stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    path: String,
    type: String,
    userId: Number
  }

  toggle({ params }) {
    if (this.userIdValue) {
      this.toggleComplete(!params.completed)
    } else {
      alert("Sign in or Create an Account to complete " + this.typeValue + '.')
    }
  }

  undo() {
    this.toggleComplete(false)
  }

  toggleComplete(shouldComplete) {
    Rails.ajax({
      type: 'PUT',
      data: new URLSearchParams({ complete: shouldComplete, type: this.typeValue }).toString(),
      url: this.pathValue,
      success: (_data, _status, xhr) => {
        this.element.outerHTML = xhr.response
      }
    })
  }
}
