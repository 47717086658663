import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "mobileNav", "profileNav", "sideNav" ]
  SCROLL_THRESHOLD = 288;

  updateSideNavPosition(e) {
    if (this.hasSideNavTarget) {
      if (!this.isSmallScreen() && document.documentElement.scrollTop > this.SCROLL_THRESHOLD) {
        this.sideNavTarget.style.marginTop = `${document.documentElement.scrollTop - this.SCROLL_THRESHOLD}px`;
      } else {
        this.sideNavTarget.style.marginTop = '0px'
      }
    }
  }

  toggleMobileNav() {
    this.mobileNavTarget.classList.toggle('hidden')
  }

  toggleProfileNav() {
    this.profileNavTarget.classList.toggle('hidden')
  }

  isSmallScreen() {
    return document.body.clientWidth < 1024
  }
}
