import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "video" ]

  jump({ params }) {
    const timestamp = params.time
    this.videoTarget.currentTime = this.secondsFromTimestamp(timestamp)
  }

  secondsFromTimestamp(timestamp) {
    // 00 : 00 : 00.000
    const timestampRegex = /(?:([0-5]?[0-9]):)?([0-5]?[0-9]):([0-5][0-9].[0-9][0-9][0-9])/
    let matches = timestamp.match(timestampRegex)
    return (Number.parseInt(matches[1]) * 3600) +
      (Number.parseInt(matches[2]) * 60) +
      Number.parseFloat(matches[3])
  }
}
